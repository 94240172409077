import React, { useCallback, useEffect } from "react";
import { QuoteStateTypes, QuoteDto, ShoppingCartDto } from "api";
import { StatusBanner } from "framework/components/StatusBanner";
import { TFunction } from "i18next";
import styled from "styled-components";
import { Dispatch } from "redux";
import { shoppingCartsViewSagas } from "applications/shoppingCarts/sagas/shoppingCartsViewSagas";
import { useSelector } from "react-redux";
import {
  getClonedIdentifier,
  getCloneStatus
} from "applications/shoppingCarts/reducers/shoppingCartsViewReducer";
import { usePrevious } from "framework/hooks/usePrevious";
import { getUserInformation } from "applications/common/reducers/userReducer";
import { RequestStatus } from "framework/state/requestStatus";
import { userActions } from "applications/common/actions/userActions";
import { routes } from "utilities/routes";
import { useNavigate } from "react-router-dom";

const StatusBannerContainer = styled.div`
  width: 100%;
`;

interface InformationBannerProps {
  quote: QuoteDto | undefined;
  shoppingCart: ShoppingCartDto | undefined;
  t: TFunction;
  dispatch: Dispatch;
}

export const QuoteStatusBanner = ({
  quote,
  shoppingCart,
  dispatch,
  t
}: InformationBannerProps): JSX.Element | null => {
  const navigate = useNavigate();

  const cloneStatus = useSelector(getCloneStatus);
  const clonedId = useSelector(getClonedIdentifier);
  const userInformation = useSelector(getUserInformation);

  const prevCloneStatus = usePrevious(cloneStatus);

  const navigateTo = useCallback(
    (guid: string) => {
      if (guid === userInformation?.selectedShoppingCartGuid) {
        navigate(routes.shoppingCart);
      } else {
        navigate(`${routes.shoppingCart}/${guid}`);
      }
    },
    [navigate, userInformation?.selectedShoppingCartGuid]
  );

  useEffect(() => {
    if (
      prevCloneStatus === RequestStatus.Pending &&
      cloneStatus === RequestStatus.Completed &&
      clonedId != null
    ) {
      navigateTo(clonedId);

      if (clonedId) {
        dispatch(userActions.updateSelectedShoppingCartGuid(clonedId));
      }
    }
  }, [cloneStatus, clonedId, dispatch, navigateTo, prevCloneStatus]);

  return (
    <StatusBannerContainer>
      {quote?.stateId === QuoteStateTypes.WaitingForQuote ? (
        <StatusBanner
          icon={"abb/time"}
          headerText={t("Approval might be needed for this quote before proceeding to send")}
          status="warning"
          shape={"long"}
        />
      ) : quote?.stateId === QuoteStateTypes.Rejected && shoppingCart ? (
        <StatusBanner
          icon={"abb/error-circle-1"}
          headerText={quote?.rejectMessage ? t("Rejected with message:") : t("Quote not approved")}
          text={
            quote?.rejectMessage ? (
              <b>
                {t(
                  "Please note: This quote has been rejected. If needed, please clone the content of the cart and create a new quote request."
                )}
              </b>
            ) : (
              t("Please re-open the quote and modify before sending for approval")
            )
          }
          subHeaderText={quote?.rejectMessage ?? undefined}
          status="error"
          shape={"long"}
          onClick={() => {
            dispatch(
              shoppingCartsViewSagas.clone.createAction({
                cloneShoppingCartCommand: { shoppingCartGuid: shoppingCart.guid }
              })
            );
          }}
          buttonText={t("Clone cart")}
          buttonIsLoading={cloneStatus === RequestStatus.Pending}
        />
      ) : quote?.stateId === QuoteStateTypes.Quote ? (
        <StatusBanner
          icon={"abb/check-mark"}
          headerText={t("Quote approved")}
          status="success"
          shape={"long"}
        />
      ) : quote?.stateId === QuoteStateTypes.QuoteWaitingForApproval ? (
        <StatusBanner
          icon={"abb/time"}
          headerText={t("Quote is waiting for approval")}
          status="warning"
          shape={"long"}
        />
      ) : quote?.stateId === QuoteStateTypes.WaitingForRevision ? (
        <StatusBanner
          icon={"abb/time"}
          headerText={t("Quote is waiting for acknowledge the revision")}
          status="warning"
          shape={"long"}
        />
      ) : quote?.stateId === QuoteStateTypes.Revised ? (
        <StatusBanner
          icon={"abb/error-circle-1"}
          headerText={t("Quote was revised")}
          status="error"
          shape={"long"}
        />
      ) : quote?.stateId === QuoteStateTypes.Draft ? (
        <StatusBanner
          icon={"abb/warning-circle-2"}
          headerText={t("Quote is draft state")}
          status="warning"
          shape={"long"}
        />
      ) : null}
    </StatusBannerContainer>
  );
};
