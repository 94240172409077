import { AccessRequestsApi } from "./../../api/apis/AccessRequestsApi";
import { CompaniesApi } from "./../../api/apis/CompaniesApi";
import { UsersApi } from "./../../api/apis/UsersApi";
import {
  SystemSettingsApi,
  OrderApi,
  Configuration,
  StatisticsApi,
  RolesApi,
  AccessRightsApi,
  ApplicationNotificationsApi,
  CountriesApi,
  ShoppingCartsApi,
  TimeZonesApi,
  TermsAndConditionsApi,
  ModificationSalesApi,
  SelectorApi,
  EmailTemplatesApi,
  ProductExplorerApi,
  ProductComparatorApi,
  ReleaseNotesApi,
  GlobalSettingApi,
  DeliveryTimesApi,
  LifecycleManagementApi,
  TechDocApi,
  TeamsApi
} from "../../api";
import { TranslationApi } from "api/apis/TranslationApi";

import { preMiddleware } from "../components/auth/authMiddleware";
import { ProductSelectionMatrixApi } from "api/apis/ProductSelectionMatrixApi";
import { HelpRequestApi } from "../../api/apis/HelpRequestApi";
import { PricesApi } from "../../api/apis/PricesApi";
import { ProductsApi } from "api/apis/ProductsApi";
import { TrainingApi } from "api";
import { FirmwareUpdatesApi } from "api";
import { SupportApi } from "api";

export class ApiRegistry {
  constructor(
    public companiesApi: CompaniesApi,
    public systemSettingsApi: SystemSettingsApi,
    public accessRequestsApi: AccessRequestsApi,
    public orderApi: OrderApi,
    public usersApi: UsersApi,
    public statisticsApi: StatisticsApi,
    public rolesApi: RolesApi,
    public accessRightsApi: AccessRightsApi,
    public productSelectionMatrixApi: ProductSelectionMatrixApi,
    public translationApi: TranslationApi,
    public helpRequestApi: HelpRequestApi,
    public pricesApi: PricesApi,
    public countriesApi: CountriesApi,
    public applicationNotificationsApi: ApplicationNotificationsApi,
    public shoppingCartsApi: ShoppingCartsApi,
    public productsApi: ProductsApi,
    public timeZonesApi: TimeZonesApi,
    public termsAndConditionsApi: TermsAndConditionsApi,
    public trainingApi: TrainingApi,
    public modificationSalesApi: ModificationSalesApi,
    public emailTemplatesApi: EmailTemplatesApi,
    public selectorApi: SelectorApi,
    public productExplorerApi: ProductExplorerApi,
    public productComparatorApi: ProductComparatorApi,
    public releaseNotesApi: ReleaseNotesApi,
    public firmwareUpdateApi: FirmwareUpdatesApi,
    public globalSettingsApi: GlobalSettingApi,
    public deliveryTimesApi: DeliveryTimesApi,
    public supportApi: SupportApi,
    public lifecycleManagementApi: LifecycleManagementApi,
    public techDocApi: TechDocApi,
    public teamsApi: TeamsApi
  ) {}
}

const basePath = process.env.REACT_APP_BASEPATH;

const apiRegistry: ApiRegistry = new ApiRegistry(
  new CompaniesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new SystemSettingsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new AccessRequestsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new OrderApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new UsersApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new StatisticsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new RolesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new AccessRightsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new ProductSelectionMatrixApi(
    new Configuration({ middleware: [preMiddleware], basePath: basePath })
  ),
  new TranslationApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new HelpRequestApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new PricesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new CountriesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new ApplicationNotificationsApi(
    new Configuration({ middleware: [preMiddleware], basePath: basePath })
  ),
  new ShoppingCartsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new ProductsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new TimeZonesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new TermsAndConditionsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new TrainingApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new ModificationSalesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new EmailTemplatesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new SelectorApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new ProductExplorerApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new ProductComparatorApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new ReleaseNotesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new FirmwareUpdatesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new GlobalSettingApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new DeliveryTimesApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new SupportApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new LifecycleManagementApi(
    new Configuration({ middleware: [preMiddleware], basePath: basePath })
  ),
  new TechDocApi(new Configuration({ middleware: [preMiddleware], basePath: basePath })),
  new TeamsApi(new Configuration({ middleware: [preMiddleware], basePath: basePath }))
);

export function getApiRegistry(): ApiRegistry {
  return apiRegistry;
}
